<template>
	<div id="explain">
		<common-header></common-header>
		<!--        <h1 class="title">轻重缓急工具使用说明</h1>-->
		<div class="main">
			<div class="exp-txt" v-html="msg">
				<!--                <div class="explain">-->
				<!--                    <h2>轻重缓急工具使用说明：</h2>-->
				<!--                    <p>当前版本为：V1.2</p>-->
				<!--                    <p>更新时间为：2020-06-04</p>-->
				<!--                </div>-->
				<!--                <div class="use">-->
				<!--                    <h2>基础使用部分：</h2>-->
				<!--                    <p>1、注册登录 work.yunhjc.cn</p>-->
				<!--                    <p>2、点击左边的新增事物</p>-->
				<!--                    <p>3、写下来要做的事情，（把所有能想到的事情，全部写上去，平时花了时间的东西）</p>-->
				<!--                    <p>例如：看书、研究某某代码、打电话给某某、买某某资料……</p>-->
				<!--                    <p>4、然后把事情，分为重要，还是不重要，紧急还是不紧</p>-->
				<!--                    <p>5、最后拖到相应的区域</p>-->
				<!--                    <p>6、就可以时刻的提醒自己，那些事优先要搞定</p>-->
				<!--                    <p>7、任务的紧急 、 重要程度是会动态变化的，到时就，再把他拖放到相应的区域就OK了</p>-->
				<!--                </div>-->
				<!--                <div class="vip-use">-->
				<!--                    <h2>特权使用部分：</h2>-->
				<!--                    <p>1、点击左侧的导出json数据，即可备份本地数据。</p>-->
				<!--                    <p>2、点击左侧的选择文件，选择曾经导出过的数据，既可恢复数据。</p>-->
				<!--                </div>-->
				<!--                <div class="problem">-->
				<!--                    <h2>其他问题部分：</h2>-->
				<!--                    <p>1、如果遇到任何其他问题，可以通过 左侧下方的 优化建议&意见反馈 与管理员进行沟通。</p>-->
				<!--                </div>-->
			</div>
			<div class="copyright">
				©2020-2021轻重缓急All Rights Reserved
				<a href="https://beian.miit.gov.cn/" target="_blank"
					>粤ICP备15072947号-6
				</a>
			</div>
			<div class="btn-box" v-show="msg">
				<div class="sign-out" @click="back">返回</div>
				<div class="get-into" @click="getInto">进入</div>
			</div>
		</div>
	</div>
</template>

<script>
import CommonHeader from '@/components/CommonHeader'
export default {
	name: 'Explain',
	components: { CommonHeader },
	data() {
		return {
			msg: '',
		}
	},
	mounted() {
		this.getData()
	},
	methods: {
		getData() {
			this.$http({
				url: this.api.otherGuide,
			})
				.then((res) => {
					console.log(res)
					const { data } = res
					this.msg = data
				})
				.catch(() => {})
		},
		back() {
			this.$router.back()
		},
		getInto() {
			this.$router.push({ path: '/home' })
		},
	},
}
</script>

<style lang="less" scoped>
#explain {
	min-width: 1200px;
	background: #f9f9f9;
	padding-bottom: 127px;

	.title {
		font-size: 36px;
		font-family: Source Han Sans SC Regular,
			Source Han Sans SC Regular-Regular;
		font-weight: 400;
		text-align: center;
		color: #70ccff;
		padding: 12px 0 20px 0;
	}

	.main {
		width: 1200px;
		background: #ffffff;
		margin: 80px auto 0 auto;
		padding: 30px 108px 72px 108px;
		box-sizing: border-box;
		.exp-txt {
			h2 {
				font-size: 18px;
				font-family: Source Han Sans SC Regular,
					Source Han Sans SC Regular-Regular;
				font-weight: 400;
				text-align: left;
				color: #333333;
				line-height: 17px;
				padding-bottom: 20px;
			}

			p {
				font-size: 14px;
				font-family: Source Han Sans SC Regular,
					Source Han Sans SC Regular-Regular;
				font-weight: 400;
				text-align: left;
				color: #5d5d5d;
				line-height: 24px;
			}

			.explain,
			.use,
			.vip-use,
			.problem {
				p:last-of-type {
					padding-bottom: 20px;
				}
			}
		}

		.btn-box {
			margin-top: 20px;
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
		}

		.sign-out,
		.get-into {
			width: 140px;
			height: 48px;
			background: #d3d3d3;
			border-radius: 4px;
			font-size: 18px;
			font-family: Source Han Sans SC Regular,
				Source Han Sans SC Regular-Regular;
			font-weight: 400;
			text-align: center;
			color: #ffffff;
			letter-spacing: 1px;
			line-height: 48px;
			cursor: pointer;
			display: inline-block;
		}

		.get-into {
			background: #70ccff;
			margin-left: 326px;
		}
	}
	.copyright {
        left: 1050px;
        bottom: 30px;
        position: absolute;
		box-sizing: border-box;
		text-align: center;
		font-size: 14px;
	}
}
</style>
